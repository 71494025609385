<main
  class="tw-flex tw-w-full tw-mx-auto tw-flex-col tw-bg-background-alt tw-px-6 tw-pt-6 tw-pb-4 tw-text-main"
  [ngClass]="{
    'tw-min-h-screen': clientType === 'web',
    'tw-min-h-full': clientType === 'browser' || clientType === 'desktop',
  }"
>
  <a
    *ngIf="!hideLogo"
    [routerLink]="['/']"
    class="tw-w-[175px] tw-block tw-mb-12 [&>*]:tw-align-top"
  >
    <bit-icon [icon]="logo"></bit-icon>
  </a>

  <div
    class="tw-text-center tw-mb-6"
    [ngClass]="{ 'tw-max-w-md tw-mx-auto': titleAreaMaxWidth === 'md' }"
  >
    <div class="tw-mx-auto tw-max-w-28 sm:tw-max-w-32">
      <bit-icon [icon]="icon"></bit-icon>
    </div>

    <ng-container *ngIf="title">
      <!-- Small screens -->
      <h1 bitTypography="h3" class="tw-mt-2 sm:tw-hidden">
        {{ title }}
      </h1>
      <!-- Medium to Larger screens -->
      <h1 bitTypography="h2" class="tw-mt-2 tw-hidden sm:tw-block">
        {{ title }}
      </h1>
    </ng-container>

    <div *ngIf="subtitle" class="tw-text-sm sm:tw-text-base">{{ subtitle }}</div>
  </div>

  <div
    class="tw-grow tw-w-full tw-max-w-md tw-mx-auto tw-flex tw-flex-col tw-items-center sm:tw-min-w-[28rem]"
    [ngClass]="{ 'tw-max-w-md': maxWidth === 'md', 'tw-max-w-3xl': maxWidth === '3xl' }"
  >
    <div
      class="tw-rounded-2xl tw-mb-10 tw-mx-auto tw-w-full sm:tw-bg-background sm:tw-border sm:tw-border-solid sm:tw-border-secondary-300 sm:tw-p-8"
    >
      <ng-content></ng-content>
    </div>
    <ng-content select="[slot=secondary]"></ng-content>
  </div>

  <footer *ngIf="!hideFooter" class="tw-text-center tw-mt-6">
    <div *ngIf="showReadonlyHostname" bitTypography="body2">
      {{ "accessing" | i18n }} {{ hostname }}
    </div>
    <ng-container *ngIf="!showReadonlyHostname">
      <ng-content select="[slot=environment-selector]"></ng-content>
    </ng-container>
    <ng-container *ngIf="!hideYearAndVersion">
      <div bitTypography="body2">&copy; {{ year }} Bitwarden Inc.</div>
      <div bitTypography="body2">{{ version }}</div>
    </ng-container>
  </footer>
</main>
